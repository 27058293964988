<template>
  <section class="page-container">
    <!-- <a-row>
      <img
        src="../../assets/image/logo.png"
        style="width:9%;min-width:120px;margin:32px 0 0 32px"
        alt
      />
    </a-row> -->
    <a-row class="login-container" style="background: rgba(14, 78, 143,0.8);">
      <a-row class="form-wrap">
        <h2>欢迎登录</h2>
        <a-form :form="form" @submit="handleSubmit">
          <a-form-item style="text-align:left">
            <a-input
              v-decorator="[
                                'username',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: '账号不能为空'
                                        }
                                    ]
                                }
                            ]"
              size="large"
              type="text"
              placeholder="账号"
            >
              <a-icon slot="prefix" type="user" style="color:rgba(255,255,255,.55)" />
            </a-input>
          </a-form-item>
          <a-form-item style="text-align:left">
            <a-input
              v-decorator="[
                                'password',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: '密码不能为空'
                                        }
                                    ]
                                }
                            ]"
              size="large"
              type="password"
              placeholder="密码"
            >
              <a-icon slot="prefix" type="lock" style="color:rgba(255,255,255,.55)" />
            </a-input>
          </a-form-item>
          <a-row>
            <a-button
              type="primary"
              :loading="iconLoading"
              html-type="submit"
              style="width:100%;margin-top:20px"
              size="large"
            >登录</a-button>
          </a-row>
        </a-form>
      </a-row>
    </a-row>
    <section class="photo-container" style="background:rgba(18, 86, 151,0.5)">
      <a-row class="wrap">
        <a-row class="pic-wrap">
          <img src="../../assets/image/bg-image/login-image.png" alt />
        </a-row>
        <a-row class="link">
          <!-- <a-row v-for="item in showList" :key="item.key" v-show="item.key == key">
            <img :src="item.imageUrl" style="width:250px" alt />
          </a-row> -->
        </a-row>
      </a-row>
    </section>
    <div class="modal"></div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      iconLoading: false,
      showList: [
        // {text:'基筑营区工程APP',imageUrl:require('../../assets/image/bg-image/login-logo.png'),btnText:'软件下载',key:1,id:2},
        {
          // text: '扫码下载App',
          // imageUrl: require('../../assets/image/bg-image/qrCode.png'),
          imageUrl:require('../../assets/image/logo.png'),
          btnText: '返回',
          key: 1,
          id: 1
        }
      ],
      key: 1
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  methods: {
    ...mapActions(['Login']),
    handleSubmit(e) {
      e.preventDefault()
      this.iconLoading = true
      this.form.validateFields((err, formData) => {
        if (!err) {
          formData.grant_type = 'password'
          this.Login(formData)
            .then(() => {
              this.queryUserDetails()
              // let _data = res.data || {}
              // this.$store.commit(
              //     'SET_COMPANYID',
              //     _data.company_id
              // )
              // this.$store.commit('SET_USERNICKNAME', _data.name)
              // this.$store.commit(
              //     'SET_PHONE',
              //     _data.mobile || _data.phone
              // )
              // this.$router.push({ name: 'home' })


              this.iconLoading = false
            })
            .catch(() => {
              this.iconLoading = false
            })
        } else {
          this.iconLoading = false
        }
      })
    },
    queryUserDetails() {
      this.$api
        .queryUserDetails({ id: this.$store.state.userInfo.userId })
        .then((res) => {
          if (res.code === 200) {
            let _data = res.data || {}
            this.$store.commit(
              'SET_COMPANYID',
              _data.company_id
            )
            this.$store.commit('SET_USERNICKNAME', _data.name)
            this.$store.commit(
              'SET_PHONE',
              _data.mobile || _data.phone
            )
            this.$router.push({ name: 'home' })
          } else {
            this.$message.error('获取账号信息异常')
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../assets/image/bg-image/bg-login.png") no-repeat;
  background-size: 100% 100%;
  .modal {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 660px;
    height: 400px;
    border-radius: 4px;
    text-align: center;
    margin-top: -220px;
    margin-right: -330px;
    z-index: 4;
    background: rgba(4, 36, 100, 0.75);
    filter: blur(2px);
    -moz-filter: blur(2px);
    -webkit-filter: blur(2px);
  }
  .photo-container {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 300px;
    height: 400px;
    border-radius: 4px;
    text-align: center;
    margin-top: -220px;
    margin-right: 30px;
    z-index: 6;
    background: rgba(46, 167, 224, 0.12);
    .wrap {
      height: 100%;
      position: relative;
      .pic-wrap {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        img {
          width: 100%;
        }
      }
      .link {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 120px;
          opacity: 0.9;
        }
        p {
          font-size: 15px;
          margin-top: 10px;
          color: rgba(255, 255, 255, 0.8);
        }
        button {
          width: 91px;
          height: 26px;
          background: rgba(255, 255, 255, 0.15);
          border: 1px solid rgba(131, 210, 251, 0.23);
          border-radius: 3px;
          color: rgba(255, 255, 255, 0.8);
          font-size: 13px;
          margin-top: 4px;
        }
      }
    }
  }
  .login-container {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 360px;
    height: 400px;
    border-radius: 4px;
    text-align: center;
    margin-top: -220px;
    margin-right: -330px;
    z-index: 6;

    .form-wrap {
      padding: 20px 26px 20px 26px;
      height: 100%;
      background: rgba(144, 144, 144, 0.08);
    }
    h2 {
      color: #ffffff;
      margin: 26px 0 36px 0;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 3.2px;
      text-align: left;
    }
  }
}
</style>
